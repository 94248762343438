.c-footer {
    background-color: rgb(var(--rgb-charcoal));

    &__container {
        position: relative;
        padding-top: max(var(--y-65), 50px);
        padding-right: var(--x-100);
        padding-left: var(--x-100);
    }

    &__scroll-button {
        position: absolute;
        top: 0;
        right: var(--x-40);
        translate: 0 -50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-blue));
        transition: background-color 300ms;

        &:hover {
            background-color: rgb(var(--rgb-dark-blue));
        }

        svg {
            rotate: -90deg;
            width: 15px;
            height: 15px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        padding-bottom: 65px;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: initial;
            padding-bottom: 60px;
        }
    }

    &__left {
        @media screen and (max-width: 1024px) { order: 2; }
    }

    &__address {
        display: inline-block;
        margin-bottom: 50px;
        font-size: var(--fz-17);
        line-height: 1.3em;
        color: rgb(var(--rgb-white));

        @media screen and (max-width: 1024px) { display: none; }
    }

    &__logos {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: -20px;
    }

    &__logo-container {
        margin-top: 20px;

        &:first-child {
            width: 130px;

            @media screen and (max-width: 600px) { width: 86px; }
        }
        &:nth-child(2) {
            width: 121px;

            @media screen and (max-width: 600px) { width: 89px; }
        }

        &:last-child {
            width: 160px;

            @media screen and (max-width: 600px) { width: 111px; }
        }

        &:not(:last-child) {
            margin-right: 40px;

            @media screen and (max-width: 600px) { margin-right: 30px; }
        }
    }

    a.c-footer__logo-container {
        display: block;
    }

    &__logo {
        width: 100%;
        height: 100%;
    }

    &__right {
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: 1024px) {
            justify-content: space-between;
            order: 1;
            width: 100%;
            margin-bottom: 75px;
        }
    }

    &__list {
        margin-right: var(--x-70);
    }

    &__list-item:not(:last-child) {
        margin-bottom: 15px;
    }

    &__link,
    &__info-text {
        font-size: 1.5rem;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__link {
        font-weight: 500;
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-blue));
        }
    }

    &__info-text {
        display: block;
        margin-bottom: 5px;
    }

    &__phone span {
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-blue));
        transition: color 300ms var(--out-cubic);
    }

    &__phone:hover span {
        color: rgb(var(--rgb-blue));
    }

    &__copyright {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 35px;
        padding-bottom: 35px;
        border-top: 1px solid rgba(var(--rgb-white), 0.3);

        @media screen and (max-width: 500px) {
            flex-direction: column;
            gap: 15px;
        }
    }

    &__copyright-wrapper-left,
    &__copyright-wrapper-right {
        gap: 20px;
        display: flex;
        align-items: center;
        width: auto;
    }

    &__copyright-wrapper-right {
        justify-content: flex-end;

        @media screen and (max-width: 500px) {
            justify-content: flex-start;
        }
    }

    &__copyright-item,
    &__copyright-link {
        font-size: 1.2rem;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__copyright-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__copyright-icon-svg {
        width: 14px;
        height: 14px;
        fill: rgb(var(--rgb-white));
        transition: opacity 300ms;
    }

    &__copyright-icon:hover &__copyright-icon-svg {
        opacity: 0.5;
    }

    &__copyright-link {
        font-weight: 600;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }
    }
}