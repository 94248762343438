.c-button-search {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(var(--rgb-charcoal));
    transition: background-color 300ms;

    @media screen and (max-width: 500px) {
        width: 45px;
        height: 45px;
    }

    &:hover {
        background-color: rgb(var(--rgb-blue));
    }

    svg {
        width: 22px;
        height: 22px;
        stroke: rgb(var(--rgb-white));
        stroke-width: 2px;
        transition: opacity 300ms;

        @media screen and (max-width: 500px) {
            width: 19px;
            height: 19px;
        }

        &:last-child {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            opacity: 0;
            pointer-events: none;
        }
    }
}