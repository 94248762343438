@use "../base/typography";

.c-home-banner {
    overflow: hidden;

    &__container {
        padding: 0 40px;

        @media screen and (max-width: 900px) { padding: 0; }
    }

    &__swiper-text {
        position: relative;
        padding-bottom: 90px;
        padding-left: var(--x-75);

        @media screen and (max-width: 900px) { padding-right: var(--x-75); }
    }

    &__text-block {
        max-width: 760px;
    }

    &__title {
        @extend .c-huge-title;

        @media screen and (max-width: 900px) { font-size: max(var(--fz-65), 3.8rem); }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--blue;
        margin-top: var(--y-45);

        @media screen and (max-width: 900px) { margin-top: var(--y-30); }
    }

    &__waves {
        position: absolute;
        bottom: 40px;
        right: -60px;
        z-index: -1;

        @media screen and (max-width: 1300px) { right: -70px; }
        @media screen and (max-width: 1200px) { right: -80px; }
        @media screen and (max-width: 1100px) { right: -90px; }
        @media screen and (max-width: 1000px) { right: -100px; }
        @media screen and (max-width: 900px) {
            right: -60px;
            bottom: 25px;
        }
    }

    &__waves-icon {
        display: block;
        width: clamp(237px, 36vw, 600px);
    }

    &__fast-links {
        position: relative;
        z-index: 5;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% - 60px);
        padding-top: max(var(--y-50), 30px);
        padding-bottom: max(var(--y-50), 30px);
        margin-left: 30px;
        background-color: rgb(var(--rgb-charcoal));

        @media screen and (max-width: 900px) {
            width: calc(100% - 40px);
            margin-left: 20px;
        }
    }

    &__fast-links-block {
        width: 25%;

        @media screen and (max-width: 800px) { width: 50%; }
    }

    &__fast-link {
        display: block;
        width: 100%;
        height: 100%;
        padding-right: var(--x-60);
        padding-left: var(--x-60);
    }

    &__fast-links-block:not(:last-child) &__fast-link {
        border-right: 1px solid rgb(var(--rgb-white));
    }

    &__fast-links-block:first-child &__fast-link {
        @media screen and (max-width: 900px) {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                left: var(--x-60);
                height: 1px;
                background-color: rgb(var(--rgb-white));
            }
        }
    }

    &__fast-links-block:nth-child(2) &__fast-link {
        @media screen and (max-width: 900px) {
            position: relative;
            border-right: none;

            &::after {
                content: "";
                position: absolute;
                right: var(--x-60);
                bottom: 0;
                left: 0;
                height: 1px;
                background-color: rgb(var(--rgb-white));
            }
        }
    }

    &__fast-links-block:first-child &__fast-link,
    &__fast-links-block:nth-child(2) &__fast-link {
        @media screen and (max-width: 900px) { padding-bottom: 12px; }
    }

    &__fast-links-block:nth-child(3) &__fast-link,
    &__fast-links-block:nth-child(4) &__fast-link {
        @media screen and (max-width: 900px) { padding-top: 12px; }
    }

    &__fast-link-text-block {
        padding-top: 8px;
        padding-bottom: 8px;

        svg {
            width: 40px;
            height: 40px;
            margin-bottom: 12px;
            stroke: rgb(var(--rgb-blue));
            stroke-width: 7px;
            fill: none;

            @media screen and (max-width: 700px) {
                width: 30px;
                height: 30px;
            }
        }

        span {
            display: block;
            font-size: var(--fz-20);
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-white));
            transition: color 300ms;

            @media screen and (max-width: 360px) { font-size: 1.6rem; }
        }
    }

    &__fast-link:hover &__fast-link-text-block span {
        color: rgb(var(--rgb-blue));
    }

    &__fast-links + .swiper {
        margin-top: calc(0px - (var(--fast-links-height) / 2));

        @media screen and (max-width: 900px) { margin-top: calc((0px - (var(--fast-links-height) / 2)) - 20px); }
    }

    &__picture-container {
        width: 100%;
    }

    &__picture {
        position: relative;
        width: 100%;
        min-height: 382px;
        padding-top: 39.71%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__credits {
        position: absolute;
        right: 30px;
        bottom: 30px;
        @media screen and (max-width: 800px) { right: 15px; bottom: 15px; }
    }

    .swiper-pagination {
        --swiper-pagination-top: initial;
        --swiper-pagination-bottom: 25px;
        --swiper-pagination-color: rgb(var(--rgb-blue));
        --swiper-pagination-bullet-size: 5px;
        --swiper-pagination-bullet-width: 5px;
        --swiper-pagination-bullet-height: 5px;
        --swiper-pagination-bullet-inactive-color: rgb(var(--rgb-blue));
        --swiper-pagination-bullet-inactive-opacity: 0.5;
        --swiper-pagination-bullet-opacity: 1;
        --swiper-pagination-bullet-horizontal-gap: 7px;
        --swiper-pagination-bullet-border-radius: 50%;

        left: calc(var(--x-75) - var(--swiper-pagination-bullet-horizontal-gap))!important;
        text-align: initial!important;
    }

    .swiper-pagination .swiper-pagination-bullet-active {
        width: 7px;
        height: 7px;
    }
}