.c-pagination {
    &__container,
    &__list {
        display: flex;
        align-items: center;
    }

    &__list {
        margin-right: 20px;
    }

    &__list-item:not(:last-child) {
        margin-right: 20px;

        @media screen and (max-width: 400px) { margin-right: 10px; }
    }

    &__page {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-white));

        &.--active {
            background-color: rgb(var(--rgb-blue));
        }

        span {
            padding-bottom: 5px;
            font-size: 1.5rem;
            font-weight: 700;
            color: rgb(var(--rgb-charcoal));
            transition: color 300ms;
        }

        &:hover span {
            color: rgb(var(--rgb-blue));
        }

        &.--active span {
            color: rgb(var(--rgb-white));
        }
    }

    &__arrows {
        display: flex;
        justify-content: space-between;
        width: 46px;
        padding-top: 3px;
    }

    &__prev-button,
    &__next-button {
        width: 14px;
        height: 14px;
        font-family: initial;
        font-size: 0;

        &.--inactive {
            opacity: 0.5;
            pointer-events: none;
        }

        svg {
            width: 100%;
            height: 100%;
            stroke: rgb(var(--rgb-charcoal));
            stroke-width: 2.5px;
            transition: stroke 300ms;
        }

        &:hover svg {
            stroke: rgb(var(--rgb-blue));
        }
    }

    &__prev-button svg {
        rotate: 180deg;
    }
}