.c-header {
    position: relative;
    height: var(--header-height);

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 30px var(--x-40);

        @media screen and (max-width: 500px) { padding: 40px var(--x-40) 0; }
    }

    &__logo-container {
        position: relative;
        display: block;
        width: 145px;
        height: 70px;

        @media screen and (max-width: 500px) {
            width: 123px;
            height: 58px;
        }
    }

    &__logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 300ms;

        &.--white {
            opacity: 0;
        }
    }

    &__right,
    &__list,
    &__buttons {
        display: flex;
        align-items: center;
    }

    &__list {
        @media screen and (max-width: 1024px) { display: none; }
    }

    &__list-item:not(:last-child) {
        margin-right: var(--x-50);
    }

    &__link {
        span {
            font-size: var(--fz-18);
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-charcoal));
            transition: color 300ms;
        }

        &:hover span {
            color: rgb(var(--rgb-blue));
        }
    }

    &__buttons {
        margin-left: var(--x-60);
    }

    .c-button-search + .c-button-hamburger {
        margin-left: 20px;

        @media screen and (max-width: 500px) { margin-left: 10px; }
    }
}