@use "../base/typography";

.c-showcase {
    &__container {
        display: flex;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 900px) {
            flex-direction: column;
            align-items: initial;
        }
    }

    &__text-block {
        position: relative;
        overflow: hidden;
        isolation: isolate;
        flex: 1;
        padding-top: max(var(--y-120), 75px);
        padding-right: var(--x-100);
        padding-bottom: max(var(--y-130), 50px);
        padding-left: var(--x-100);
        background-color: rgb(var(--rgb-blue));

        &.--with-image {
            padding-right: max(var(--x-100), 60px);
            margin-right: -45px;

            @media screen and (max-width: 900px) {
                order: 2;
                padding-right: var(--x-100);
                margin-top: -30px;
                margin-right: 0;
            }
        }
    }

    &__surtitle {
        margin-bottom: 10px;
        font-size: var(--fz-18);
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
    }

    &__title {
        margin-bottom: 40px;
        font-size: var(--fz-50);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__button {
        @extend .c-button;
        @extend .c-button--white;
    }

    &__waves {
        position: absolute;
        bottom: 40px;
        left: -5px;
        z-index: -1;

        @media screen and (max-width: 900px) {
            right: -80px;
            bottom: 35px;
            left: initial;
        }
    }

    &__waves-icon {
        display: block;
        width: 285px;
        @media screen and (max-width: 900px) { width: 200px; }
    }

    &__picture-container {
        width: 705px;

        @media screen and (max-width: 1300px) { width: 655px; }
        @media screen and (max-width: 1200px) { width: 605px; }
        @media screen and (max-width: 1100px) { width: 555px; }
        @media screen and (max-width: 1000px) { width: 505px; }
        @media screen and (max-width: 900px) {
            order: 1;
            width: 100%;
            padding-right: var(--x-40);
            padding-left: var(--x-40);
        }
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 59%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}