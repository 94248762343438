.c-infos {
    &__container {
        display: flex;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid rgb(var(--rgb-light-gray));
        border-bottom: 1px solid rgb(var(--rgb-light-gray));

        @media screen and (max-width: 1400px) {
            flex-direction: column;
            padding: 0;
            border: none;
        }
    }

    &__blocks {
        display: flex;

        @media screen and (max-width: 1400px) {
            padding-top: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid rgb(var(--rgb-light-gray));
        }

        @media screen and (max-width: 500px) {
            flex-direction: column;
            padding: 0;
            border: none;
        }

        &:first-child {
            margin-right: min(var(--x-70), 70px);

            @media screen and (max-width: 1400px) {
                border-top: 1px solid rgb(var(--rgb-light-gray));
                margin-right: 0;
            }
            @media screen and (max-width: 500px) { border: none; }
        }
    }

    &__block {
        &:not(:last-child) {
            margin-right: clamp(50px, var(--x-70), 70px);

            @media screen and (max-width: 500px) { margin: 0; }
        }

        @media screen and (max-width: 500px) {
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid rgb(var(--rgb-light-gray));
        }
    }

    &__blocks:first-child &__block:first-child {
        @media screen and (max-width: 500px) { border-top: 1px solid rgb(var(--rgb-light-gray)); }
    }

    &__surtitle {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.6em;
        color: rgba(var(--rgb-charcoal), 0.6);
        white-space: nowrap;
    }

    &__value {
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-charcoal));
    }
}