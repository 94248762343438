@use "../../base/typography";

.c-overlay-menu {
    --menu-width: 520px;
}

.c-overlay-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 20;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 700ms var(--in-out-quart);
        z-index: -1;
        cursor: default;
    }

    &__container {
        display: flex;
        width: var(--menu-width);
        height: calc(var(--vh, 1vh) * 100);
        padding-top: calc(var(--header-height) - 30px);
        position: absolute;
        top: 0;
        left: 100%;
        right: calc(0px - var(--menu-width));
        overflow: hidden;
        background-color: rgb(var(--rgb-blue));
        transition-property: right, left,;
        transition-duration: 1000ms, 1000ms;
        transition-timing-function: var(--in-out-quart), var(--in-out-quart);

        @media screen and (max-width: 700px) {
            right: 0px;
            width: 100%;
            padding-top: var(--header-height);
        }
        @media screen and (max-width: 500px) { padding-top: calc(var(--header-height) + 30px); }
    }

    &__scrollbar-container {
        width: 100%;
    }

    &__scrollbar {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--rgb-gray));
            border-radius: 10px;
        }
    }

    &__content {
        padding: 0 var(--x-60);
        opacity: 0;
        transform: translate3d(100px, 0, 0);
        transition-property: opacity, transform;
        transition-duration: 1000ms;
        transition-timing-function: var(--out-quart);
        transition-delay: 200ms;
    }

    &__interfaces {
        position: relative;
        transition-property: height;
        transition-duration: 1000ms;
        transition-timing-function: var(--in-out-quart);
    }

    &__interface-container {
        width: 100%;
        height: 0px;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        transition-property: height;
        transition-duration: 1ms;
        transition-delay: 1000ms;
    }

    &__interface-container:has(.is-active) {
        transition-delay: 0ms;
        height: 100%;
    }

    &__interface {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        pointer-events: none;

        &.is-active {
            transform: none;
            opacity: 1;
            pointer-events: all;
        }

        &.is-on-the-right {
            transform: translate3d(150%, 0, 0);
        }

        &.is-on-the-left {
            transform: translate3d(-150%, 0, 0);
        }

        &.has-animation {
            transition-property: opacity, transform;
            transition-duration: 700ms;
            transition-timing-function: var(--in-out-quart);
        }
    }

    &__interface[data-menu-interface="main"] {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc((var(--vh, 1vh) * 100) - var(--header-height));
    }

    &__link-container:not(:last-child) {
        margin-bottom: 30px;
    }

    &__link-container:first-child,
    &__back-link + &__link-container,
    &__back-link + &__secondary-link-container {
        margin-top: 70px;

        @media screen and (max-width: 700px) { margin-top: 40px; }
        @media screen and (max-width: 500px) { margin-top: 50px; }
    }

    &__link {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    &__link-text {
        margin-right: 20px;
        font-size: var(--fz-30);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        text-align: left;
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-charcoal));
        }
    }

    &__link-svg {
        position: relative;
        translate: 0 9px;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        stroke: rgb(var(--rgb-white));
        stroke-width: 2px;
    }

    &__bottom-section {
        padding-top: 60px;
        padding-bottom: 40px;
    }

    &__list {
        margin-bottom: 35px;
    }

    &__list-item:not(:last-child) {
        margin-bottom: 10px;
    }

    &__bottom-link {
        span {
            font-size: 2rem;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
            transition: color 300ms;
        }

        &:hover span {
            color: rgb(var(--rgb-charcoal));
        }
    }

    &__bottom-button {
        @extend .c-button;
        @extend .c-button--white;
        justify-content: center;
        width: 100%;
    }

    &__back-link {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
    }

    &__back-svg {
        position: relative;
        translate: 0 2px;
        rotate: -180deg;
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        margin-right: 15px;
        stroke: rgb(var(--rgb-white));
        stroke-width: 2px;
    }

    &__back-text {
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
        transition: color 300ms;
    }

    &__back-link:hover &__back-text {
        color: rgb(var(--rgb-charcoal));
    }

    &__secondary-link-container {
        margin-bottom: 30px;
    }

    &__secondary-link {
        font-size: var(--fz-30);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        text-align: left;
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-charcoal));
        }

        span:not(:first-child) {
            display: block;
            font-size: 1.7rem;
            font-weight: 400;
            line-height: 1.3em;
            color: rgb(var(--rgb-white));
            transition: color 300ms;
        }

        &:hover span:not(:first-child) {
            color: rgb(var(--rgb-charcoal));
        }
    }
}

.show-overlay-menu {
    .c-overlay-menu {
        pointer-events: all;
        visibility: visible;
    }
}

.show-overlay-menu:not(.closing-overlay-menu) {
    .c-overlay-menu {
        &__container {
            right: 0%;
            left: calc(100% - var(--menu-width));

            @media screen and (max-width: 700px) { left: 0%; }
        }

        &__content {
            opacity: 1;
            transform: none;
            transition-delay: 500ms;
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }

    .c-header {
        &__logo {
            @media screen and (max-width: 700px) {
                opacity: 0;
                transition-delay: 700ms;

                &.--white {
                    opacity: 1;
                    z-index: 25;
                }
            }
        }
    }

    .c-button-hamburger {
        position: relative;
        z-index: 25;
        background-color: rgb(var(--rgb-white));

        &:hover {
            background-color: rgb(var(--rgb-charcoal));
        }

        &__line {
            background-color: rgb(var(--rgb-charcoal));

            &:first-child,
            &:last-child {
                opacity: 0;
            }
            &:nth-child(2) {
                rotate: -45deg;
            }
            &:nth-child(3) {
                rotate: 45deg;
            }
        }

        &:hover .c-button-hamburger__line {
            background-color: rgb(var(--rgb-white));
        }
    }

    .c-button-search {
        @media screen and (max-width: 700px) {
            position: relative;
            z-index: 25;
            transition-delay: 700ms;

            &:hover {
                background-color: rgb(var(--rgb-dark-blue));
            }
        }
    }
}

.show-overlay-menu.closing-overlay-menu {
    .c-header {
        &__logo {
            @media screen and (max-width: 700px) {
                opacity: 1;
                transition-delay: 300ms;

                &.--white {
                    opacity: 0;
                    z-index: 25;
                }
            }
        }
    }
    .c-button-hamburger {
        position: relative;
        z-index: 25;
        transition-delay: 600ms;

        &__line {
            transition: translate 400ms var(--out-cubic), rotate 400ms, background-color 300ms 700ms;
        }
    }
    .c-button-search {
        @media screen and (max-width: 700px) {
            position: relative;
            z-index: 25;
            transition-delay: 700ms;
        }
    }
}