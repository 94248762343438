.c-button-hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(var(--rgb-blue));
    transition: background-color 300ms;

    @media screen and (max-width: 500px) {
        width: 45px;
        height: 45px;
    }

    &:hover {
        background-color: rgb(var(--rgb-charcoal));
    }

    &__lines {
        position: relative;
        width: 20px;
        height: 16px;

        @media screen and (max-width: 500px) {
            width: 18px;
            height: 14px;
        }
    }

    &__line {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 5px;
        background-color: rgb(var(--rgb-white));
        transition: translate 400ms var(--out-cubic), rotate 400ms, background-color 300ms;

        &:first-child {
            top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 50%;
            translate: 0 -50%;
        }
        &:last-child {
            bottom: 0;
        }
    }
}