@use "../base/typography";

.c-schedule {
    --svg-dimensions: 20px;
}

.c-schedule {
    &__title {
        @extend .c-large-title;
        margin-bottom: var(--y-30);
    }

    &__blocks {
        display: flex;
        width: 100%;

        @media screen and (max-width: 900px) { flex-direction: column; }
    }

    &__block {
        width: calc(50% - 20px);
        padding: 30px max(var(--x-50), 40px);
        background-color: rgb(var(--rgb-light-blue));
        border: 1px solid rgb(var(--rgb-light-blue));

        @media screen and (max-width: 900px) { width: 100%; }
    }

    &__block + &__block {
        margin-left: 40px;

        @media screen and (max-width: 900px) {
            margin-top: 20px;
            margin-left: 0;
        }
    }

    &__block-title {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.6em;

        &:has(svg) {
            margin-left: calc(0px - var(--svg-dimensions) - 10px);
        }

        svg {
            translate: 0 2px;
            flex-shrink: 0;
            width: var(--svg-dimensions);
            height: var(--svg-dimensions);
            margin-right: 10px;
            stroke: rgb(var(--rgb-charcoal));
            stroke-width: 1.5px;
        }
    }

    &__block-infos {
        display: block;
        margin-bottom: 5px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.6em;
    }

    &__block-text {
        display: block;
        margin-bottom: 5px;
        font-size: min(var(--fz-17), 1.7rem);
        line-height: 1.6em;
    }
}