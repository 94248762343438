.c-sections {
    --sections-list-gap-x: 15px;
    --sections-list-gap-y: 30px;
}

.c-sections {
    &__surtitle {
        font-size: 1.7rem;
        line-height: 1.3em;
    }

    &__title {
        max-width: 600px;
        margin-bottom: 50px;
        font-size: max(var(--fz-60), 3.5rem);
        font-weight: 600;
        line-height: 1.3em;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--sections-list-gap-x) * 2));
        margin-right: calc(0px - var(--sections-list-gap-x));
        margin-bottom: calc(0px - var(--sections-list-gap-y));
        margin-left: calc(0px - var(--sections-list-gap-x));
    }

    &__list-item {
        width: 33.33%;
        padding: 0 var(--sections-list-gap-x);
        margin-bottom: var(--sections-list-gap-y);

        @media screen and (max-width: 1000px) { width: 50%; }
        @media screen and (max-width: 700px) { width: 100%; }
    }
}