.c-input-field {
    --input-field-color: rgb(var(--rgb-charcoal));
    --input-field-placeholder-color: rgb(var(--rgb-gray));
    --input-field-background: rgb(var(--rgb-white));
    --input-field-border: rgb(var(--rgb-light-gray));
    --input-field-border-active: rgb(var(--rgb-charcoal));
    --input-field-border-error: rgb(var(--rgb-red));
}

.c-input-field {
    position: relative;

    &__input-container {
        position: relative;
    }

    &__input {
        width: 100%;
        padding: 8px 0;
        margin-bottom: 1px;
        font-family: var(--ff);
        font-size: var(--fz-16);
        line-height: 1.6em;
        color: var(--input-field-color);
        border: 0;
        border-bottom: 1px solid var(--input-field-border);
        border-radius: 0; // Nécéssaire même si 0 pour éviter les coins ronds en iOS
        background-color: var(--input-field-background);
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;
    }

    &__input:focus {
        border-color: var(--input-field-border-active);
        border-width: 2px;
        margin-bottom: 0px; // Pour éviter le "skip" de 1px lorsque le border passe de 1px à 2px au focus
    }

    &__input {
        box-shadow: 0 0 0 100px var(--input-field-background) inset; // Choisir la couleur voulu pour le input (Cache le background bleu de l'autofill)
    }

    &--error &__input {
        border-color: var(--input-field-border-error);
    }

    // Couleur des Placeholders
    ::-webkit-input-placeholder { color: var(--input-field-placeholder-color); }
    ::-moz-placeholder { color: var(--input-field-placeholder-color); }
    :-ms-input-placeholder {  color: var(--input-field-placeholder-color); }
    :-moz-placeholder { color: var(--input-field-placeholder-color); }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        font-size: var(--fz-16) !important;
        border-bottom: 1px solid var(--input-field-border-active) !important;
        -webkit-text-fill-color: var(--input-field-color);
        caret-color: var(--input-field-color);
        margin-bottom: 1px !important;
    }

    :-webkit-autofill::first-line {
        font-family: var(--ff);
        font-size: var(--fz-16);
    }
}
