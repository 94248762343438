.c-breadcrumbs {
    &__list {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        color: rgb(var(--rgb-charcoal));
        line-height: 24px;
    }

    &__home-link {
        margin-right: 10px;

        svg {
            width: 12px;
            height: 12px;
            fill: rgb(var(--rgb-blue));
            transition: fill 300ms;
        }

        &:hover svg {
            fill: rgb(var(--rgb-dark-blue));
        }
    }

    &__home-link + &__link-item &__space,
    &__home-link + &__item &__space {
        display: none;
    }

    &__link-item,
    &__item {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.3em;
        color: inherit;

        @media screen and (max-width: 400px) { font-size: 1.3rem; }
    }

    &__link-item {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }
    }

    &__space {
        translate: 0 -2px;
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-right: 10px;
        margin-left: 10px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-charcoal));
    }

    &__link-item:first-child &__space,
    &__item:first-child &__space {
        display: none;
    }
}