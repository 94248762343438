.c-contact-infos {
    --infos-block-gap-x: 19px;
    --infos-block-gap-y: 30px;
    --svg-dimensions: 20px;
}

.c-contact-infos {
    &__container {
        overflow: hidden;
    }

    &__waves {
        width: 100%;
        min-width: 1024px;
        margin-bottom: 35px;
    }

    &__blocks {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--infos-block-gap-x) * 2));
        margin-right: calc(0px - var(--infos-block-gap-x));
        margin-bottom: calc(0px - var(--infos-block-gap-y));
        margin-left: calc(0px - var(--infos-block-gap-x));
    }

    &__block {
        width: 33.33%;
        padding: 0 var(--infos-block-gap-x);
        margin-bottom: var(--infos-block-gap-y);

        @media screen and (max-width: 1100px) { width: 50%; }
        @media screen and (max-width: 700px) { width: 100%; }
    }

    &__block-content {
        height: 100%;
        padding: 30px 50px;
        background-color: rgb(var(--rgb-charcoal));
    }

    &__block-title {
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;

        svg {
            translate: 0 8px;
            flex-shrink: 0;
            width: var(--svg-dimensions);
            height: var(--svg-dimensions);
            margin-right: 10px;
            margin-left: calc(0px - var(--svg-dimensions) - 10px);
            stroke: rgb(var(--rgb-white));
            stroke-width: 1.5px;
        }

        &.--schedule svg {
            stroke-width: 2px;
        }

        span {
            font-size: 2rem;
            font-weight: 700;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
        }
    }

    &__block-text {
        display: block;
        margin-bottom: 8px;
        font-size: 1.5rem;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
    }

    &__block-link {
        display: block;
        width: fit-content;
        font-size: 1.5rem;
        font-weight: 600;
        color: rgb(var(--rgb-white));
        text-decoration: none;

        span {
            display: block;
            padding-bottom: 7px;
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-blue));
            transition: color 300ms var(--out-cubic);
        }

        &:hover span {
            color: rgb(var(--rgb-blue));
        }
    }
}