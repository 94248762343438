@use "../../abstract/placeholders";

.c-rubric-card {
    position: relative;
    border-bottom: 1px solid rgb(var(--rgb-light-gray));
    -webkit-tap-highlight-color: transparent;

    &:first-child {
        border-top: 1px solid rgb(var(--rgb-light-gray));
    }

    &__header {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 150px;
        padding: 30px 40px 30px 0;
        cursor: pointer;

        @media screen and (max-width: 1024px) {
            min-height: 120px;
            padding-right: 30px;
        }
        @media screen and (max-width: 768px)  {
            min-height: 100px;
            padding-right: 20px;
        }
    }

    &__picture {
        flex-shrink: 0;
        width: 90px;
        height: 90px;
        margin-right: 30px;
        overflow: hidden;
        position: relative;
        object-fit: cover;

        @media screen and (max-width: 600px) { display: none; }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__text {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__surtitle {
        display: block;
        margin-bottom: 12px;
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-charcoal));
    }

    &__title {
        font-size: var(--fz-25);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-charcoal));
        transition: color 300ms;
    }

    &__header:hover &__title {
        color: rgb(var(--rgb-blue));
    }

    &.js-accordion-opened &__title {
        color: rgb(var(--rgb-blue));
    }

    &__plus {
        position: relative;
        width: 20px;
        height: 20px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            will-change: transform;
            height: 2px;
            background-color: rgb(var(--rgb-charcoal));
            opacity: 1;
            transition-property: background-color, opacity;
            transition-duration: 300ms;
        }

        &::before {
            transform: translate3d(-50%, -50%, 0);
        }

        &::after {
            transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }
    }

    &__header:hover &__plus {
        &::before,
        &::after {
            background-color: rgb(var(--rgb-blue));
        }
    }

    &.js-accordion-opened &__plus {
        &::before,
        &::after {
            background-color: rgb(var(--rgb-blue));
        }

        &::after {
            opacity: 0;
        }
    }

    &__content {
        height: 0;
        overflow: hidden;
        will-change: height;
    }

    &__transition {
        opacity: 0;
        transform: translateY(100px);
        transition-property: opacity, transform;
        transition-duration: 700ms;
        transition-timing-function: var(--in-out-quart);
    }

    &.js-accordion-opened &__transition {
        opacity: 1;
        transform: none;
        transition-delay: 100ms;
    }

    &__transition > :first-child {
        padding-top: 0;
        margin-top: 0;
    }

    &__cms-content,
    &__documents,
    &__gallery {
        padding-top: var(--y-60);
    }

    &__share-container {
        padding-top: 45px;
        padding-bottom: 25px;
    }

    &__share-button {
        display: flex;
        align-items: center;

        span {
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.3em;
            transition: color 300ms;
        }

        &:hover span {
            color: rgb(var(--rgb-blue));
        }

        svg {
            translate: 0 2px;
            width: 10px;
            height: 12px;
            margin-left: 10px;
            fill: rgb(var(--rgb-charcoal));
            transition: fill 300ms;
        }

        &:hover svg {
            fill: rgb(var(--rgb-blue));
        }
    }

    &:not(.js-accordion-opened) {
        iframe {
            pointer-events: none;
        }
    }
}
