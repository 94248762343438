.c-overlay-search {
    --svg-dimensions: 22px;
}

.c-overlay-search {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    overflow: hidden;
    pointer-events: none;
    z-index: 20;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 700ms var(--in-out-quart);
        z-index: -1;
        cursor: default;
    }

    &__container {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        translate: 0 -100%;
        display: flex;
        justify-content: center;
        width: 100%;
        height: calc(215px + var(--header-height));
        padding-top: calc(var(--header-height) + 40px);
        padding-right: var(--x-40);
        padding-left: var(--x-40);
        background-color: rgb(var(--rgb-white));
        transition-property: translate;
        transition-duration: 1000ms;
        transition-timing-function: var(--in-out-quart);

        @media screen and (max-width: 700px) { height: calc(180px + var(--header-height)); }
        @media screen and (max-width: 500px) { height: calc(150px + var(--header-height)); }
    }

    &__search {
        translate: 0 -30px;
        width: 100%;
        max-width: 880px;
        opacity: 0;
        pointer-events: none;
        transition-property: translate, opacity;
        transition-duration: 600ms, 600ms;
        transition-timing-function: var(--in-out-quart), linear;
        transition-delay: 500ms, 500ms;
    }

    .c-search-field {
        width: 100%;
        position: relative;

        .c-search-field__input {
            width: 100%;
            height: 100%;
            padding-top: 15px;
            padding-right: 60px;
            padding-bottom: 15px;
            border: none;
            border-bottom: 2px solid rgb(var(--rgb-charcoal));
            background-color: rgb(var(--rgb-white));

            font-family: var(--ff);
            font-size: 2.5rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-charcoal));

            @media screen and (max-width: 500px) { font-size: 2rem; }
            @media screen and (max-width: 400px) { font-size: 1.8rem; }

            &::-webkit-input-placeholder { color: rgb(var(--rgb-gray)); }
            &::-moz-placeholder { color: rgb(var(--rgb-gray)); }
            &:-ms-input-placeholder { color: rgb(var(--rgb-gray)); }
            &:-moz-placeholder { color: rgb(var(--rgb-gray)); }
            &::placeholder { color: rgb(var(--rgb-gray)); }
        }

        .c-search-field__input:focus {
            border-color: rgb(var(--rgb-charcoal));
            border-width: 2px;
            margin-bottom: -1px; // Pour éviter le "skip" de 1px lorsque le border passe de 1px à 2px au focus
        }

        .c-search-field__search-button {
            position: absolute;
            top: calc(50% + 3px);
            right: 0;
            translate: 0 -50%;
            width: var(--svg-dimensions);
            height: var(--svg-dimensions);

            svg {
                width: 100%;
                height: 100%;
                stroke: rgb(var(--rgb-blue));
                stroke-width: 2px;
                transition: stroke 300ms;
            }

            &:hover svg {
                stroke: rgb(var(--rgb-charcoal));
            }
        }

        .c-search-field__x {
            position: absolute;
            top: calc(50% + 3px);
            right: calc(var(--svg-dimensions) + 8px);
            translate: 0 -50%;
            display: block;
            width: 14px;
            height: 14px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;
            font-family: initial;

            svg {
                width: 100%;
                height: 100%;
                stroke: rgb(var(--rgb-charcoal));
                stroke-width: 2px;
                transition: opacity 300ms, stroke 300ms;
            }

            &:hover svg {
                stroke: rgb(var(--rgb-blue));
            }
        }

        &[data-inputvalue]:not([data-inputvalue=""]) .c-search-field__x {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.show-overlay-search {
    .c-overlay-search {
        pointer-events: all;
        visibility: visible;
    }
}

.show-overlay-search:not(.closing-overlay-search) {
    .c-overlay-search {
        &__container {
            translate: 0 0;
        }

        &__search {
            translate: 0 0;
            opacity: 1;
            pointer-events: all;
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }

    header {
        z-index: 30;
    }

    .c-button-search {
        svg {
            &:first-child {
                opacity: 0;
                pointer-events: none;
            }

            &:last-child {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

.show-overlay-search.closing-overlay-search {
    .c-overlay-search {
        &__search {
            translate: 0 -30px;
            opacity: 0;
            pointer-events: none;
            transition-duration: 400ms, 400ms;
            transition-delay: 0ms, 0ms;
        }
    }

    header {
        z-index: 30;
    }

    .c-button-search {
        svg {
            &:first-child {
                opacity: 1;
                pointer-events: all;
            }

            &:last-child {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}