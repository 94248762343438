@use "../base/typography";

.c-results {
    &__list-item:not(:last-child) {
        margin-bottom: 40px;
    }

    &__result {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding-bottom: 40px;
        border-bottom: 1px solid rgb(var(--rgb-light-gray));
    }

    &__result-content {
        flex: 1;
    }

    &__result:has(picture) &__result-content {
        padding-right: var(--x-90);

        @media screen and (max-width: 600px) { padding-right: 0; }
    }

    &__result-category {
        display: inline-block;
        margin-bottom: 15px;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.6em;
    }

    &__result-title {
        margin-bottom: 25px;
        font-size: var(--fz-30);
        font-weight: 500;
        line-height: 1.3em;

        mark {
            color: rgb(var(--rgb-blue));
        }
    }

    &__result-description {
        margin-bottom: 25px;
        font-size: min(var(--fz-17), 1.7rem);
        line-height: 1.6em;

        mark {
            font-weight: 600;
            color: rgb(var(--rgb-blue));
        }
    }

    &__result-button {
        @extend .c-button;
        @extend .c-button--empty;
        pointer-events: none;
    }

    &__result:hover &__result-button {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-dark-blue));
        --button-border-color: rgb(var(--rgb-dark-blue));

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }
    }

    &__result-link {
        @extend .c-paragraph;
        font-weight: 700;
        text-decoration: none;

        span {
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-blue));
            transition: color 300ms var(--out-cubic);
        }
    }

    &__result:hover &__result-link {
        span {
            color: rgb(var(--rgb-blue));
        }
    }

    &__result-picture-container {
        flex-shrink: 0;
        width: 90px;
        height: 90px;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__result-picture {
        width: 100%;
        height: 100%;
    }
}