.c-filter-swiper {
    display: flex;
    align-items: center;
    padding: var(--y-nus-23) var(--x-nus-40);
    background-color: rgb(var(--rgb-grey-background));

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: normal;
    }

    &__container {
        flex: 1;
        position: relative;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid rgb(var(--rgb-light-gray));
        border-bottom: 1px solid rgb(var(--rgb-light-gray));

        @media screen and (max-width: 500px) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .swiper-slide {
        width: auto !important;
    }

    &__option {
        display: block;
        width: fit-content;
        margin-right: clamp(50px, var(--x-70), 70px);
        @media screen and (max-width: 500px) { margin-right: 20px; }
    }

    &__surtitle {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.6em;
        color: rgba(var(--rgb-charcoal), 0.6);
        white-space: nowrap;
    }

    &__value {
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-charcoal));
    }

    &__button-swiper-navigation-of-filter {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
    }
}
