@use "../base/typography";

.c-periods {
    --svg-dimensions: 20px;
}

.c-periods {
    &__title {
        @extend .c-large-title;
        margin-bottom: var(--y-30);
    }

    &__list-item {
        padding-top: 45px;
        padding-bottom: 45px;
        border-bottom: 1px solid rgb(var(--rgb-light-gray));

        &:first-child {
            border-top: 1px solid rgb(var(--rgb-light-gray));
        }
    }

    &__blocks {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: -20px;
        margin-right: calc(0px - clamp(50px, var(--x-70), 70px));
    }

    &__block {
        margin-top: 20px;
        margin-right: clamp(50px, var(--x-70), 70px);
    }

    &__surtitle {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.6em;
        color: rgba(var(--rgb-charcoal), 0.6);
        white-space: nowrap;
    }

    &__value {
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-charcoal));
    }

    &__place {
        padding-left: 25px;
    }

    &__blocks + &__place {
        margin-top: 40px;
    }

    &__place-content {
        display: flex;
        align-items: flex-start;

        svg {
            translate: 0 4px;
            flex-shrink: 0;
            width: var(--svg-dimensions);
            height: var(--svg-dimensions);
            margin-right: 7px;
            margin-left: calc(0px - var(--svg-dimensions) - 7px);
            stroke: rgb(var(--rgb-charcoal));
        }
    }

    &__place-text span {
        display: block;
        line-height: 1.3em;

        &:first-child {
            font-size: 2rem;
            font-weight: 500;
        }
        &:last-child {
            font-size: 1.5rem;
        }
    }

    &__place-link {
        display: inline-block;
        margin-top: 20px;
        font-size: 1.5rem;
        font-weight: 600;
        text-decoration: none;

        span {
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-blue));
            transition: color 300ms var(--out-cubic);
        }

        &:hover span {
            color: rgb(var(--rgb-blue));
        }
    }
}