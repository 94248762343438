.o-section {
    &.--page-title {
        margin-top: 30px;
        margin-bottom: 65px;
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--cms-content {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--small-documents {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--documents {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--gallery {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--rubrics {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--form {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--showcase {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-40);
        padding-left: var(--x-40);

        @media screen and (max-width: 900px) { padding: 0; }
    }

    &.--infos {
        margin-bottom: var(--y-85);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--schedule {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--periods {
        margin-top: var(--y-65);
        margin-bottom: var(--y-120);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--sections {
        margin-bottom: var(--y-110);
        padding-right: var(--x-120);
        padding-left: var(--x-120);
    }

    &.--contact-infos {
        margin-top: 45px;
        margin-bottom: var(--y-80);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--results {
        margin-bottom: 65px;
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--pagination {
        margin-top: 65px;
        margin-bottom: var(--y-100);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--home-banner {
        margin-top: 70px;
        margin-bottom: max(var(--y-120), 80px);

        @media screen and (max-width: 900px) { margin-top: 60px; }
    }

    &.--page-title + section {
        margin-top: 0;
    }
}

.o-section {
    &.--page-title {
        position: relative;
        z-index: 5;
    }
}

.o-layout.--page-contact {
    .o-section {
        &.--page-title {
            margin-bottom: 45px;
        }
    }
}