@use "../base/typography";

.c-page-title {
    --svg-dimensions: 20px;
}

.c-page-title {
    .c-breadcrumbs + &__text-block {
        margin-top: 10px;
    }

    &__text-block {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: initial;
        }
    }

    &__title {
        @extend .c-huge-title;
        color: rgb(var(--rgb-charcoal));

        mark {
            color: rgb(var(--rgb-blue));
        }
    }

    &__buttons {
        display: flex;
        flex-shrink: 0;
        margin-left: var(--x-80);

        @media screen and (max-width: 1200px) {
            flex-wrap: wrap;
            margin-top: 20px;
            margin-left: -10px;
        }
    }

    &__scrolldown {
        @extend .c-button;
        @extend .c-button--charcoal;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-stroke;

        @media screen and (max-width: 1200px) {
            margin-top: 10px;
            margin-left: 10px;
        }

        svg {
            rotate: 90deg;
            stroke-width: 2px;
        }
    }

    &__subscription {
        @extend .c-button;
        @extend .c-button--blue;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-fill;
        margin-left: 10px;

        @media screen and (max-width: 1200px) { margin-top: 10px; }
    }

    &__picture-container {
        margin-top: max(var(--y-65), 55px);
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 49.4%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__search-and-select {
        display: flex;
        align-items: center;
        margin-top: 60px;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 30px;
        }
    }

    &__search {
        width: 100%;
        max-width: 530px;
        margin-right: 40px;

        @media screen and (max-width: 600px) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    .c-search-field {
        width: 100%;
        position: relative;

        .c-search-field__input {
            width: 100%;
            height: 100%;
            padding-right: 60px;
            padding-bottom: 8px;
            border: none;
            border-bottom: 1px solid rgb(var(--rgb-light-gray));
            background-color: rgb(var(--rgb-white));

            font-family: var(--ff);
            font-size: 1.7rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-charcoal));

            &::-webkit-input-placeholder { color: rgb(var(--rgb-gray)); }
            &::-moz-placeholder { color: rgb(var(--rgb-gray)); }
            &:-ms-input-placeholder { color: rgb(var(--rgb-gray)); }
            &:-moz-placeholder { color: rgb(var(--rgb-gray)); }
            &::placeholder { color: rgb(var(--rgb-gray)); }
        }

        .c-search-field__input:focus {
            border-color: rgb(var(--rgb-charcoal));
            border-width: 2px;
            margin-bottom: -1px; // Pour éviter le "skip" de 1px lorsque le border passe de 1px à 2px au focus
        }

        .c-search-field__search-button {
            position: absolute;
            top: 50%;
            right: 0;
            translate: 0 -50%;
            width: var(--svg-dimensions);
            height: var(--svg-dimensions);

            svg {
                width: 100%;
                height: 100%;
                stroke: rgb(var(--rgb-blue));
                stroke-width: 2px;
                transition: stroke 300ms;
            }

            &:hover svg {
                stroke: rgb(var(--rgb-charcoal));
            }
        }

        .c-search-field__x {
            position: absolute;
            top: 50%;
            right: calc(var(--svg-dimensions) + 8px);
            translate: 0 -50%;
            display: block;
            width: 14px;
            height: 14px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;
            font-family: initial;

            svg {
                width: 100%;
                height: 100%;
                stroke: rgb(var(--rgb-charcoal));
                stroke-width: 2px;
                transition: opacity 300ms, stroke 300ms;
            }

            &:hover svg {
                stroke: rgb(var(--rgb-blue));
            }
        }

        &[data-inputvalue]:not([data-inputvalue=""]) .c-search-field__x {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__select {
        width: 100%;
        max-width: 225px;
    }
}