.c-gallery {
    --gallery-height: 400px;
    @media screen and (max-width: 768px) { --gallery-height: 335px; }
    @media screen and (max-width: 500px) { --gallery-height: 200px; }
    --gallery-margin: 20px;
    @media screen and (max-width: 768px) { --gallery-margin: 15px; }
    @media screen and (max-width: 500px) { --gallery-margin: 10px; }
}

.c-gallery {
    &__container {
        display: flex;
        flex-flow: row nowrap;
        max-width: 920px;
        height: var(--gallery-height);
    }

    &__left {
        flex: 1;
    }

    &__right {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        width: calc((var(--gallery-height) - var(--gallery-margin)) / 2 );
        margin-left: var(--gallery-margin);
    }

    &__block {
        position: relative;
        display: none;
        height: 100%;
    }

    &__link {
        position: relative;
        display: block;
        height: 100%;
        outline: none;
        cursor: zoom-in;
    }

    &__background-filter {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgb(var(--rgb-charcoal));
        opacity: 0;
        transition: opacity 300ms;
    }

    &__link:hover &__background-filter {
        opacity: 0.4;
    }

    &__block--block-1,
    &__block--block-2,
    &__block--block-3 {
        display: block;
    }

    &__block--block-1 &__link,
    &__block--block-2 &__link,
    &__block--block-3 &__link {
        display: block;
        height: 100%;
    }

    &__block--block-2,
    &__block--block-3 {
        height: calc((var(--gallery-height) - var(--gallery-margin)) / 2 );
    }

    &__block--block-3 &__link &__background-filter {
        background-color: rgb(var(--rgb-charcoal));
        opacity: 0.6;
        transition: opacity 300ms;
    }

    &__block--block-3 &__link:hover &__background-filter {
        opacity: 0.7;
    }

    &__picture {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__plus-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    &__plus {
        position: relative;
        width: 20px;
        height: 20px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            will-change: transform;
            height: 2px;
            background-color: rgb(var(--rgb-white));
        }

        &::before {
            transform: translate3d(-50%, -50%, 0);
        }

        &::after {
            transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }
    }
}
