@use "../../base/typography";

// Si ces variables ne sont pas accessibles dans ce site, il suffit de les décommenter pour les utiliser.
/*.c-overlay-cookies {
    --y-30: max(22px + (30 - 22) * ((100vw - 500px) / (1440 - 500)), 22px);
    --x-45: max(22px + (45 - 22) * ((100vw - 500px) / (1440 - 500)), 22px);
    --x-40: max(22px + (40 - 22) * ((100vw - 500px) / (1440 - 500)), 22px);
    --x-25: max(22px + (25 - 22) * ((100vw - 500px) / (1440 - 500)), 22px);
    --rgb-black: 0, 0, 0;
    --rgb-white: 255, 255, 255;
    --in-out-quart: cubic-bezier(0.76, 0, 0.24, 1);
}*/

.c-overlay-cookies {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 110;

    &__container {
        position: absolute;
        right: var(--x-40);
        bottom: var(--y-30);
        left: var(--x-40);
        display: flex;
        align-items: center;
        padding: 18px min(var(--x-25), 25px);
        background-color: rgb(var(--rgb-white));
        box-shadow: 0 4px 18px rgba(var(--rgb-black), 0.1);
        opacity: 0;
        pointer-events: none;
        transform: translateY(var(--y-30));
        transition: opacity 800ms var(--in-out-quart), transform 800ms var(--in-out-quart);
        will-change: transform;

        @media screen and (max-width: 700px) {
            flex-direction: column;
            align-items: normal;
        }
    }

    &__content {
        flex: 1;
    }

    &__title {
        font-size: calc(var(--fz-17) + 2px);
        font-weight: 700;
        line-height: 1.6em;
    }

    &__text {
        margin-top: 8px;

        a {
            font-size: inherit;
            white-space: nowrap;
        }
    }

    &__buttons {
        margin-left: var(--x-45);

        @media screen and (max-width: 700px) {
            margin-top: 20px;
            margin-left: 0;
        }

        @media screen and (max-width: 400px) {
            width: 100%;
        }
    }

    &__button-accept {
        @extend .c-button;
        @extend .c-button--blue;

        @media screen and (max-width: 700px) {
            padding: 18px 32px;
        }

        @media screen and (max-width: 400px) {
            justify-content: center;
            width: 100%;
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-cookies {
    .c-overlay-cookies {
        visibility: visible;

        &__container {
            opacity: 1;
            pointer-events: all;
            transform: none;
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-cookies.closing-overlay-cookies {
    .c-overlay-cookies {
        &__container {
            opacity: 0;
            transform: translateY(var(--y-30));
        }
    }
}