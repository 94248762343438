.c-select-field {
    position: relative;
    width: 100%;

    .tail-select {
        width: 100%;
        position: relative;
        z-index: 1;
        transition-property: z-index;

        &:not(.active) {
            transition-delay: 500ms;
        }

        &.active {
            z-index: 2;
        }

        > span {
            position: absolute;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            z-index: 2;
            pointer-events: none;
        }

        svg {
            width: 14px;
            height: 14px;
            transform: rotate(90deg);
            stroke: rgb(var(--rgb-white));
            stroke-width: 3px;
            z-index: 1;
            pointer-events: none;
            transition-property: transform, stroke;
            transition-duration: 500ms;
            transition-timing-function: var(--out-cubic), linear;
        }
    }

    .tail-select.active svg {
        transform: rotate(-90deg);
    }

    .select-label {
        padding: 18px 50px 22px 25px;
        border-radius: 60px;
        position: relative;
        background-color: rgb(var(--rgb-blue));
        z-index: 1;
        cursor: pointer;
        user-select: none;
        transition-property: color, background-color, border-radius;
        transition-duration: 500ms, 500ms, 300ms;
        transition-timing-function: linear, linear, var(--out-cubic);

        &:hover {
            background-color: rgb(var(--rgb-dark-blue));
        }
    }

    .tail-select.active .select-label {
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom: none;
        background-color: rgb(var(--rgb-blue));
    }

    .label-inner {
        display: block;
        position: relative;
        font-family: var(--ff);
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: color 300ms;
    }

    .select-dropdown {
        width: 100%;
        position: absolute;
        top: 0%;
        left: 0;
        overflow: hidden!important;
        opacity: 0;
        background-color: rgb(var(--rgb-white));
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        transition-property: top, opacity, visibility;
        transition-duration: 500ms, 500ms, 0ms;
        transition-timing-function: var(--out-cubic), var(--out-cubic), linear;
        transition-delay: 0ms, 0ms, 500ms;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top: none;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    }

    .tail-select.active .select-dropdown {
        opacity: 1;
        top: 100%;
        visibility: visible;
        pointer-events: all;
        transition-property: top, opacity, visibility;
        transition-duration: 500ms, 500ms, 0s;
        transition-delay: 0ms, 0ms, 0s;
        transition-timing-function: var(--out-cubic);
    }

    .dropdown-inner {
        max-height: 195px;
        overflow: auto;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-white));
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--rgb-light-gray));
        }
    }

    .dropdown-option {
        padding: 20px 40px;
        font-family: var(--ff);
        font-size: var(--fz-16);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-charcoal));
        background-color: rgba(var(--rgb-light-gray), 0.2);
        cursor: pointer;
        user-select: none;
        transition-property: color, background-color;
        transition-duration: 300ms;

        &:hover {
            color: rgb(var(--rgb-white));
            background-color: rgb(var(--rgb-gray));
        }

        &.selected {
            color: rgb(var(--rgb-white));
            background-color: rgb(var(--rgb-charcoal));
        }
    }

    select { // Select natif
        width: 100%;
        max-width: 280px;
        border-radius: 75px;
        padding: 18px 50px 22px 25px;
        font-family: var(--ff);
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        outline: none;
        border: none;
        background-image: url("../medias/images/icons/chevron.svg");
        background-repeat: no-repeat;
        background-position: calc(100% - 25px) 50%;
        background-size: 14px 14px;
        background-color: rgb(var(--rgb-blue));
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-appearance: none;

        &[data-select-hidden="0"] { // Permet de pouvoir scroller au select si le champs est requis et vide
            display: block !important;
            height: 0;
            padding: 0;
            margin: 0;
            opacity: 0;
        }
    }

    select::-ms-expand {
        display: none;
    }

    &--small {
        max-width: 225px;
    }
}