/*
|--------------------------------------------------------------------------
| Les variables de couleur
|--------------------------------------------------------------------------
|
| Déclaration des variables de couleurs du projet
|
*/

:root {
    // #000000 - Noir
    --rgb-black: 0, 0, 0;

    // #ffffff - Blanc
    --rgb-white: 255, 255, 255;

    // #D7EDF4 - Bleu pâle
    --rgb-light-blue: 215, 237, 244;

    // #009CD4 - Bleu
    --rgb-blue: 0, 156, 212;

    // #0E679E - Bleu foncé
    --rgb-dark-blue: 14, 103, 158;

    // #151D22 - Charcoal
    --rgb-charcoal: 21, 29, 34;

    // #B2B2B2 - Gris pâle (Tableaux et lignes)
    --rgb-light-gray: 178, 178, 178;

    // #626262 - Gris (Note)
    --rgb-gray: 98, 98, 98;

    // #eb0000 - Rouge
    --rgb-red: 235, 0, 0;
}
