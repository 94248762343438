.c-section-card {
    display: block;
    height: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 50px;
        background-color: rgb(var(--rgb-charcoal));
    }

    &__picture-container {
        overflow: hidden;
        width: 211px;
        height: 211px;
        margin-bottom: max(var(--y-65), 50px);
        border-radius: 50%;

        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

        @media screen and (max-width: 500px) {
            width: 180px;
            height: 180px;
        }
        @media screen and (max-width: 400px) {
            width: 160px;
            height: 160px;
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__text-block {
        text-align: center;
    }

    &__title,
    &__subtitle {
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        transition: color 300ms;
    }

    &:hover &__title,
    &:hover &__subtitle {
        color: rgb(var(--rgb-blue));
    }

    &__title {
        font-size: max(var(--fz-35), 30px);
    }

    &__subtitle {
        font-size: 2.2rem;
    }
}

.o-layout.--page-home {
    .c-section-card {
        &__picture-container {
            width: 280px;
            height: 280px;

            @media screen and (max-width: 500px) {
                width: 250px;
                height: 250px;
            }
        }
    }
}
