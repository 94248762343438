/*
|--------------------------------------------------------------------------
| Root
|--------------------------------------------------------------------------
|
| Déclaration de tout ce qui doit se retrouver dans le sélecteur :root.
|
*/

@use "../abstract/mixins" as *;

:root {
    /*
    |--------------------------------------------------------------------------
    | Génère les variables de taille de police
    |--------------------------------------------------------------------------
    */
    @include fontSizes(
        "14", "15", "16", "17", "18", "20", "21", "22", "25", "30", "35", "40", "50", "60", "65", "70",
    );

    /*
    |--------------------------------------------------------------------------
    | Génère les variables d'espacement
    |--------------------------------------------------------------------------
    */
    @include spacings(
        "x-25", "x-40", "x-45", "x-50", "x-60", "x-60", "x-70", "x-75", "x-80", "x-90", "x-100", "x-120", "x-200",
        "y-20", "y-25", "y-30", "y-35", "y-40", "y-45", "y-50", "y-60", "y-65", "y-70", "y-80", "y-85", "y-100", "y-110", "y-120", "y-130",
    );

    /*
    |--------------------------------------------------------------------------
    | Varibales css
    |--------------------------------------------------------------------------
    */

    // Variables pour le header
    --header-height: 130px;
    @media screen and (max-width: 500px) { --header-height: 100px; }
}
