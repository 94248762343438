.c-tooltip {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;

    &::after {
        content: "";
        position: absolute;
        background-color: rgb(var(--rgb-white));
        border-radius: 50%;
    }

    &__svg {
        position: relative;
        width: 100%;
        height: 100%;
        fill: rgb(var(--rgb-white));
        z-index: 1;
    }
}

// Les styles de la bulle
.tippy-box[data-theme~='default'],
.tippy-box[data-theme~='info-field'] {
    font-family: var(--ff);
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 10px;
    background-color: rgb(var(--rgb-white));
    color: rgb(var(--rgb-black));

    &[data-placement^='top'] > .tippy-arrow::before {
        border-top-color: rgb(var(--rgb-white));
    }

    &[data-placement^='right'] > .tippy-arrow::before {
        border-right-color: rgb(var(--rgb-white));
    }

    &[data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color: rgb(var(--rgb-white));
    }

    &[data-placement^='left'] > .tippy-arrow::before {
        border-left-color: rgb(var(--rgb-white));
    }

    .tippy-content {
        padding-top: 12px;
        padding-right: 20px;
        padding-bottom: 12px;
        padding-left: 20px;
    }
}
