@use "../../abstract/placeholders";

.c-large-document-card {
    position: relative;
    border-bottom: 1px solid rgb(var(--rgb-light-gray));
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 150px;
    padding: 30px 40px 30px 0;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        min-height: 120px;
        padding-right: 30px;
    }
    @media screen and (max-width: 768px)  {
        min-height: 100px;
        padding-right: 20px;
    }

    &:first-child {
        border-top: 1px solid rgb(var(--rgb-light-gray));
    }

    &__picture {
        flex-shrink: 0;
        width: 90px;
        height: 90px;
        margin-right: 30px;
        overflow: hidden;
        position: relative;
        object-fit: cover;

        @media screen and (max-width: 600px) { display: none; }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__content {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__surtitle {
        display: block;
        margin-bottom: 12px;
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-charcoal));
    }

    &__title {
        font-size: var(--fz-25);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-charcoal));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue));
    }

    &__svg-container {
        font-size: 0;
    }

    &__svg {
        width: 20px;
        height: 20px;
        fill: rgb(var(--rgb-charcoal));
        stroke: rgb(var(--rgb-charcoal));
        transition-property: stroke, fill;
        transition-duration: 300ms;
        transition-timing-function: linear;
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-blue));
        stroke: rgb(var(--rgb-blue));
    }
}
